import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Modal,
  Box,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Stepper,
  StepLabel,
  StepContent,
  Step,
  Button,
  Alert,
} from '@mui/material';
import { useApiSend } from '../../utils/httpClient';
import { completeTraining } from '../../endpoints/training';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function QuizQuestion({
  question,
  key,
  handleBack,
  handleNext,
  activeStep,
  steps,
}) {
  const [answer, setAnswer] = useState(null);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState(false);

  let questionWords = question.question;
  let answerOptions = [];
  Object.keys(question).forEach((key) => {
    if (key.includes('selection') && question[key]) {
      let temp = key.split('_')[1];
      answerOptions.push({
        option: temp,
        answer: question[key],
      });
    }
  });

  const checkAnswer = () => {
    setAnswerSubmitted(true);
    if (answer === question.answer) {
      setAnswerCorrect(true);
      return;
    }
    setAnswerCorrect(false);
  };

  return (
    <Grid container key={key}>
      <Grid item xs={12} padding>
        <Typography variant="body1">{questionWords}</Typography>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={10}>
        <FormControl>
          <RadioGroup
            value={answer}
            onChange={(event) => setAnswer(event.target.value)}
          >
            {answerOptions
              .sort((a, b) => a.order - b.order)
              .map((option) => {
                return (
                  <FormControlLabel
                    value={option.option}
                    control={<Radio />}
                    label={option.answer}
                  />
                );
              })}
          </RadioGroup>
        </FormControl>
      </Grid>
      {answerSubmitted ? (
        <Grid container item>
          <Alert severity={answerCorrect ? 'success' : 'error'}>
            {answerCorrect
              ? 'Correct!'
              : 'Incorrect! Answer: ' +
                answerOptions.find(
                  (option) => option.option === question.answer
                ).answer}
            <Button
              variant="contained"
              color={answerCorrect ? 'success' : 'error'}
              sx={{ marginLeft: 2 }}
              onClick={() => handleNext(answerCorrect)}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Alert>
        </Grid>
      ) : (
        <Button
          variant="contained"
          disabled={answer === null}
          size={'small'}
          color="success"
          onClick={checkAnswer}
        >
          Check Answer
        </Button>
      )}
    </Grid>
  );
}

export default function QuizModal({
  quizOpen,
  setOpen,
  quizQuestions,
  trainingId,
  refetchParent,
}) {
  let steps = [];
  for (let i = 0; i < quizQuestions.length; i++) {
    steps.push({
      label: 'Question ' + (i + 1),
      question: quizQuestions[i],
    });
  }

  const [activeStep, setActiveStep] = useState(0);
  const [questionsIncorrect, setQuestionsIncorrect] = useState(0);

  const { mutate } = useApiSend(
    completeTraining,
    (data) => {
      refetchParent();
      handleReset();
      setOpen(false);
    },
    (error) => {
      console.log(error);
    }
  );

  const handleNext = (value) => {
    if (!value) {
      setQuestionsIncorrect(questionsIncorrect + 1);
    }
    if (activeStep === steps.length - 1) {
      handleComplete();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setQuestionsIncorrect(0);
  };

  const handleComplete = () => {
    if (questionsIncorrect === 0) {
      mutate({
        id: trainingId,
      });
    } else {
      setOpen(false);
      handleReset();
    }
  };
  return (
    <Modal
      open={quizOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={style}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <QuizQuestion
                  key={step.question.id}
                  question={step.question}
                  handleBack={handleBack}
                  handleNext={(value) => handleNext(value)}
                  steps={steps}
                  activeStep={activeStep}
                />
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Modal>
  );
}
