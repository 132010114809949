import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useApiGet } from '../../utils/httpClient';
import { getUsers } from '../../endpoints/users';
import { useCallback } from 'react';
import { Add } from '@mui/icons-material';
import PeoTable from '../../Components/PeoTable';

const headCells = [
  {
    id: 'first_name',
    firstCell: true,
    label: 'First Name',
    width: '30%',
  },
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
    width: '30%',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    width: '30%',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Active?',
    width: '10%',
  },
];

export default function UserARList() {
  let [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (key, value) => {
      if (!value || value === '') {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState(
    searchParams.get('search') ? searchParams.get('search') : ''
  );
  const [delaySearch, setDelaySearch] = useState(
    searchParams.get('search') ? searchParams.get('search') : ''
  );

  // need up update search params when search changes
  useEffect(() => {
    updateSearchParams('search', search);
  }, [search, updateSearchParams]);

  const { data, isFetching } = useApiGet(
    ['user-list', rowsPerPage, page, search],
    getUsers,
    {
      enabled: true,
      retry: 1,
    },
    {
      rowsPerPage,
      page,
      search,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(delaySearch);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [delaySearch]);

  const handleClick = (event, id) => {
    window.location.href = `/users/${id}`;
  };

  const handleCreate = () => {
    window.location.href = '/users/create';
  };

  return (
    <Grid container padding={3}>
      <Grid item xs={12} paddingTop={2} paddingBottom={5}>
        <Typography
          variant="h4"
          component="div"
          textAlign={'center'}
          color="primary"
        >
          Users
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} paddingRight={2} paddingBottom={3}>
          <TextField
            label="Search"
            id="search-field"
            size="small"
            value={delaySearch}
            fullWidth={true}
            onChange={(event) => setDelaySearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          padding
          paddingBottom={3}
          textAlign={'right'}
        >
          <Button
            onClick={() => handleCreate()}
            variant="contained"
            sx={{ fontWeight: 'bold' }}
            size="small"
            startIcon={<Add />}
          >
            Create User
          </Button>
        </Grid>
      </Grid>
      <PeoTable
        data={data}
        isFetching={isFetching}
        headCells={headCells}
        handleRowClick={handleClick}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
    </Grid>
  );
}
