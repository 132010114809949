import React from 'react';
import { Grid, Typography } from '@mui/material';

const LCChapterList = () => {
  return (
    <Grid>
      <Typography variant="h1">LC Chapter List</Typography>
    </Grid>
  );
};

export default LCChapterList;
