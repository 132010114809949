import React from 'react';
import {
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
  Skeleton,
} from '@mui/material';
import { useApiGet } from '../utils/httpClient';
import { getStates } from '../endpoints/annualReports';

export default function FormStateSelector({
  formState,
  handleStateChange,
  label = 'Step',
}) {
  const { data } = useApiGet(['form-states'], getStates, {
    enabled: true,
    retry: 1,
  });

  return data ? (
    <FormControl fullWidth={true} variant="outlined" size="small">
      <InputLabel id="formState">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        fullWidth={true}
        variant="standard"
        size="small"
        value={formState}
        input={<OutlinedInput label={label} />}
        label={label}
        onClose={(event) => handleStateChange(event.target.value)}
        onChange={(event) => handleStateChange(event.target.value)}
      >
        <MenuItem value={'all'}>All</MenuItem>
        {data
          ?.sort((a, b) => a.order - b.order)
          .map((status) => (
            <MenuItem key={status.id} value={status.id}>
              {status.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  ) : (
    <Skeleton>
      <Select label="Step" />
    </Skeleton>
  );
}
