import React from 'react';

import { FormControl, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getReportList } from '../../endpoints/reports';

export default function DocumentCategorySelector({
  category,
  handleCategorySelect,
}) {
  const { data } = useApiGet(['doc-cat-selector'], getReportList, {
    enabled: true,
    retry: 1,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        {data && (
          <FormControl fullWidth>
            <InputLabel id="doc-cat-label">Category</InputLabel>
            <Select
              labelId="doc-cat-label"
              id="doc-cat-select"
              value={category}
              label="Category"
              onChange={(event) => handleCategorySelect(event.target.value)}
            >
              {data?.reports.map((docCat) => (
                <MenuItem key={docCat.id} value={docCat.id}>
                  {docCat.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
}
