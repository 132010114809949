import React, { useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useApiGet } from '../utils/httpClient';
import { getStates } from '../endpoints/lookups';

export default function StateSelector({
  handleChange,
  countryId,
  size = 'small',
  error = null,
  errorMessage = null,
  value,
  required = true,
}) {
  const { data, refetch } = useApiGet(
    ['donation-states'],
    getStates,
    {
      enabled: countryId ? true : false,
    },
    { countryId }
  );

  useEffect(() => {
    if (countryId) {
      refetch();
    }
  }, [countryId, refetch]);

  let label = 'State / Province / District';
  if (required) {
    label = 'State / Province / District *';
  }

  return data?.length > 0 ? (
    <Autocomplete
      size={size}
      onChange={(event, newValue) => handleChange(newValue)}
      getOptionLabel={(option) => option.name}
      variant="standard"
      value={
        data.find((option) => option.id === value)
          ? data.find((option) => option.id === value)
          : null
      }
      label={label}
      options={data}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={error ? errorMessage : ''}
        />
      )}
    />
  ) : null;
}
