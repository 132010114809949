import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Box, Button, Link, Divider } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getTrainingDetail } from '../../endpoints/training';
import ReactPlayer from 'react-player';
import QuizModal from './QuizModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayjs from 'dayjs';
import PageHeader from '../../Components/PageHeader';
import trainingLogo from '../../assets/training.jpg';

export default function TrainingDetail() {
  let { id } = useParams();
  const [quizOpen, setQuizOpen] = useState(false);
  const { data, refetch } = useApiGet(
    ['training-detail', id],
    getTrainingDetail,
    { enabled: id ? true : false },
    { id }
  );

  return data ? (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title={data.name} />
      </Grid>
      <Grid container item xs={12} padding>
        <Grid item xs={12} sm={7}>
          <ReactPlayer
            className="react-player"
            width="100%"
            url={data.video_url}
            playsinline={true}
            controls={true}
          />
          <Link padding>Transcript</Link>
        </Grid>
        <Grid item xs={12} sm={5} padding>
          <Typography variant="h6" component="div" fontWeight={'bold'}>
            Description
          </Typography>
          <Typography
            variant="body1"
            component="div"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {data.description}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} padding={3}>
        <Divider />
      </Grid>
      <Grid item xs={12} padding>
        <Typography variant="h6" component={'div'} fontWeight={'bold'}>
          Summary
        </Typography>
        <Typography
          variant="body1"
          component={'div'}
          sx={{ whiteSpace: 'pre-line' }}
        >
          {data.summary}
        </Typography>
      </Grid>
      <Grid item xs={12} padding={3}>
        <Divider />
      </Grid>
      <Grid container item padding>
        <Grid item>
          <Typography variant="h6" component={'div'} fontWeight={'bold'}>
            Test Your Knowledge
          </Typography>
          <Button padding onClick={() => setQuizOpen(true)}>
            Quiz
          </Button>
          {data.completed_training && (
            <Grid item display={'flex'}>
              <CheckCircleIcon color="success" fontSize="small" />
              <Typography
                variant="caption"
                component={'div'}
                color="success"
                fontStyle={'italic'}
              >
                Completed{' '}
                {dayjs(data.completed_training).format('MMM DD, YYYY')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} padding={3}>
        <Divider />
      </Grid>
      <Grid item container xs={12} padding>
        <Grid item xs={12} padding>
          <Typography variant="h6" component={'div'} fontWeight={'bold'}>
            Related Trainings
          </Typography>
        </Grid>
        {data.related_trainings.map((training) => (
          <Grid
            item
            xs={4}
            sm={3}
            md={2}
            textAlign={'center'}
            paddingLeft={2}
            paddingRight={2}
          >
            <Box
              onClick={() =>
                (window.location.href = `/training/${training.id}`)
              }
              component="img"
              src={training.icon_image_url || trainingLogo}
              sx={{ width: '100%', height: 'auto' }}
            />
            <Typography variant="body1" component={'div'}>
              {training.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <QuizModal
        quizOpen={quizOpen}
        refetchParent={refetch}
        setOpen={(value) => setQuizOpen(value)}
        quizQuestions={data.questions}
        trainingId={data.id}
      />
    </Grid>
  ) : null;
}
