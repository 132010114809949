import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Tabs,
  Tab,
  Box,
  Button,
  Divider,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { useApiGet } from '../../utils/httpClient';
import { getUser } from '../../endpoints/users';
import PageHeader from '../../Components/PageHeader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandLess } from '@mui/icons-material';

dayjs.extend(utc);

export default function UserDetail() {
  let { id } = useParams();
  const { data, isFetching } = useApiGet(
    ['user', id],
    getUser,
    {
      enabled: true,
      retry: 1,
      refetchInterval: 15 * 60 * 1000, // The data will be refetched every 15 minutes
    },
    { id }
  );

  const [tab, setTab] = useState(0);
  const [expandCommittees, setExpandCommittees] = useState(false);
  const [expandHistory, setExpandHistory] = useState(false);
  const [expandTlc, setExpandTlc] = useState(false);
  const [expandReciprocity, setExpandReciprocity] = useState(false);
  const [expandBnB, setExpandBnB] = useState(false);
  const [expandColleges, setExpandColleges] = useState(false);

  const getCurrentCommittees = () => {
    if (data?.committees) {
      return data.committees.filter((committee) => committee.ended_on === null);
    }
    return [];
  };

  const getCurrentChapter = () => {
    if (data?.member_info) {
      return data.member_info.find((chapter) => chapter.ended_on === null);
    }
    return null;
  };

  const getCurrentTlc = () => {
    if (data?.tlc) {
      return data.tlc.find((tlc) => tlc.ended_on === null);
    }
    return null;
  };

  const getCurrentReciprocity = () => {
    if (data?.reciprocity) {
      return data.reciprocity.find(
        (reciprocity) => reciprocity.ended_on === null
      );
    }
    return null;
  };

  const getCurrentBnB = () => {
    if (data?.bnb) {
      return data.bnb.find((bnb) => bnb.ended_on === null);
    }
    return null;
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }
  if (data) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <PageHeader title={`${data.first_name} ${data.last_name}`} />
        </Grid>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs
              value={tab}
              onChange={(e, newValue) => setTab(newValue)}
              centered
            >
              <Tab label="Current" value={0} />
              <Tab label="History" value={1} />
              <Tab label="Notes" value={2} />
              <Tab label="Update History" value={3} />
            </Tabs>
          </Box>
          <TabPanel value={0} sx={{ width: '100%' }}>
            <Grid item xs={12} paddingLeft={3} paddingRight={3}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={data.first_name}
                      readOnly
                      fullWidth
                      label="First Name"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={data.last_name}
                      readOnly
                      fullWidth
                      label="Last Name"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={data.email}
                      readOnly
                      fullWidth
                      label="Email"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={
                        getCurrentChapter()
                          ? getCurrentChapter().chapter
                          : 'Not a Member'
                      }
                      readOnly
                      fullWidth
                      label="Chapter"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={data.imis_id}
                      readOnly
                      fullWidth
                      label="iMIS ID (Member ID)"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={data.is_active ? 'Yes' : 'No'}
                      readOnly
                      fullWidth
                      label="Is Active"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={data.imis_user ? 'Yes' : 'No'}
                      readOnly
                      fullWidth
                      label="iMIS Authorized User"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={
                        data.last_login
                          ? dayjs(data.last_login)
                              .utc()
                              .local()
                              .format('MMM DD, YYYY H:MM A')
                          : 'N/A'
                      }
                      readOnly
                      fullWidth
                      label="Last Login"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6">Current Committes</Typography>
                  </Grid>
                  {getCurrentCommittees() &&
                    getCurrentCommittees().map((committee) => (
                      <Grid item container xs={12} key={committee.id}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={committee.committee}
                            readOnly
                            fullWidth
                            label="Committee"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={committee.position}
                            readOnly
                            fullWidth
                            label="Position"
                            variant="standard"
                          />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6">Current Contact For</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={getCurrentTlc() ? getCurrentTlc().tlc : 'N/A'}
                      readOnly
                      fullWidth
                      label="TLC"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={getCurrentBnB() ? getCurrentBnB().bnb : 'N/A'}
                      readOnly
                      fullWidth
                      label="Bed and Breakfast"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6">Reciprocity Positions</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={
                        getCurrentReciprocity()
                          ? getCurrentReciprocity().reciprocity
                          : 'N/A'
                      }
                      readOnly
                      fullWidth
                      label="TLC"
                      variant="standard"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                  >
                    <TextField
                      value={
                        getCurrentReciprocity()
                          ? getCurrentReciprocity().role
                          : 'N/A'
                      }
                      readOnly
                      fullWidth
                      label="Position"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </TabPanel>
          <TabPanel value={1} sx={{ width: '100%' }}>
            <Grid item xs={12} padding>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12} paddingLeft={3} paddingRight={3}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6" fullWidth>
                      Member History
                      <Button
                        sx={{ paddingLeft: 2 }}
                        size="small"
                        variant="text"
                        onClick={() => setExpandHistory(!expandHistory)}
                        endIcon={
                          expandHistory ? <ExpandMoreIcon /> : <ExpandLess />
                        }
                      >
                        {expandHistory ? 'Hide' : 'Show'} History
                      </Button>
                    </Typography>
                  </Grid>

                  {expandHistory &&
                    data.member_info &&
                    data.member_info.map((info) => (
                      <Grid item container xs={12} key={info.id}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.chapter}
                            readOnly
                            fullWidth
                            label="Chapter"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.full_name_at_start || 'N/A'}
                            readOnly
                            fullWidth
                            label="Name at Start"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={dayjs(info.started_on)
                              .utc()
                              .local()
                              .format('MMM DD, YYYY')}
                            readOnly
                            fullWidth
                            label="Started On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={
                              info.ended_on
                                ? dayjs(info.ended_on)
                                    .utc()
                                    .local()
                                    .format('MMM DD, YYYY')
                                : 'Current'
                            }
                            readOnly
                            fullWidth
                            label="Ended On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <Divider />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} padding>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12} paddingLeft={3} paddingRight={3}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6" fullWidth>
                      Committees
                      <Button
                        sx={{ paddingLeft: 2 }}
                        size="small"
                        variant="text"
                        onClick={() => setExpandCommittees(!expandCommittees)}
                        endIcon={
                          expandCommittees ? <ExpandMoreIcon /> : <ExpandLess />
                        }
                      >
                        {expandCommittees ? 'Hide' : 'Show'} History
                      </Button>
                    </Typography>
                  </Grid>

                  {expandCommittees &&
                    data.committees &&
                    data.committees.map((committee) => (
                      <Grid item container xs={12} key={committee.id}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={committee.committee}
                            readOnly
                            fullWidth
                            label="Committee"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={committee.position}
                            readOnly
                            fullWidth
                            label="Position"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={dayjs(committee.started_on)
                              .utc()
                              .local()
                              .format('MMM DD, YYYY')}
                            readOnly
                            fullWidth
                            label="Started On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={
                              committee.ended_on
                                ? dayjs(committee.ended_on)
                                    .utc()
                                    .local()
                                    .format('MMM DD, YYYY')
                                : 'Current'
                            }
                            readOnly
                            fullWidth
                            label="Ended On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <Divider />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} padding>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12} paddingLeft={3} paddingRight={3}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6" fullWidth>
                      TLC History
                      <Button
                        sx={{ paddingLeft: 2 }}
                        size="small"
                        variant="text"
                        onClick={() => setExpandTlc(!expandTlc)}
                        endIcon={
                          expandTlc ? <ExpandMoreIcon /> : <ExpandLess />
                        }
                      >
                        {expandTlc ? 'Hide' : 'Show'} History
                      </Button>
                    </Typography>
                  </Grid>

                  {expandTlc &&
                    data.tlc &&
                    data.tlc.map((info) => (
                      <Grid item container xs={12} key={info.id}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.tlc}
                            readOnly
                            fullWidth
                            label="TLC"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={dayjs(info.started_on)
                              .utc()
                              .local()
                              .format('MMM DD, YYYY')}
                            readOnly
                            fullWidth
                            label="Started On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={
                              info.ended_on
                                ? dayjs(info.ended_on)
                                    .utc()
                                    .local()
                                    .format('MMM DD, YYYY')
                                : 'Current'
                            }
                            readOnly
                            fullWidth
                            label="Ended On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <Divider />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} padding>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12} paddingLeft={3} paddingRight={3}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6" fullWidth>
                      Reciprocity History
                      <Button
                        sx={{ paddingLeft: 2 }}
                        size="small"
                        variant="text"
                        onClick={() => setExpandReciprocity(!expandReciprocity)}
                        endIcon={
                          expandReciprocity ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLess />
                          )
                        }
                      >
                        {expandReciprocity ? 'Hide' : 'Show'} History
                      </Button>
                    </Typography>
                  </Grid>

                  {expandReciprocity &&
                    data.reciprocity &&
                    data.reciprocity.map((info) => (
                      <Grid item container xs={12} key={info.id}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.reciprocity}
                            readOnly
                            fullWidth
                            label="Reciprocity"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.role || 'N/A'}
                            readOnly
                            fullWidth
                            label="Position"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={dayjs(info.started_on)
                              .utc()
                              .local()
                              .format('MMM DD, YYYY')}
                            readOnly
                            fullWidth
                            label="Started On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={
                              info.ended_on
                                ? dayjs(info.ended_on)
                                    .utc()
                                    .local()
                                    .format('MMM DD, YYYY')
                                : 'Current'
                            }
                            readOnly
                            fullWidth
                            label="Ended On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <Divider />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} padding>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12} paddingLeft={3} paddingRight={3}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6" fullWidth>
                      Bed and Breakfast History
                      <Button
                        sx={{ paddingLeft: 2 }}
                        size="small"
                        variant="text"
                        onClick={() => setExpandBnB(!expandBnB)}
                        endIcon={
                          expandBnB ? <ExpandMoreIcon /> : <ExpandLess />
                        }
                      >
                        {expandBnB ? 'Hide' : 'Show'} History
                      </Button>
                    </Typography>
                  </Grid>

                  {expandBnB &&
                    data.bnb &&
                    data.bnb.map((info) => (
                      <Grid item container xs={12} key={info.id}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.bnb}
                            readOnly
                            fullWidth
                            label="Bed and Breakfast"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={dayjs(info.started_on)
                              .utc()
                              .local()
                              .format('MMM DD, YYYY')}
                            readOnly
                            fullWidth
                            label="Started On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={
                              info.ended_on
                                ? dayjs(info.ended_on)
                                    .utc()
                                    .local()
                                    .format('MMM DD, YYYY')
                                : 'Current'
                            }
                            readOnly
                            fullWidth
                            label="Ended On"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <Divider />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} padding>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid item container xs={12} paddingLeft={3} paddingRight={3}>
                  <Grid
                    item
                    xs={12}
                    paddingLeft={3}
                    paddingRight={3}
                    padding
                    textAlign={'center'}
                  >
                    <Typography variant="h6" fullWidth>
                      College History
                      <Button
                        sx={{ paddingLeft: 2 }}
                        size="small"
                        variant="text"
                        onClick={() => setExpandColleges(!expandColleges)}
                        endIcon={
                          expandColleges ? <ExpandMoreIcon /> : <ExpandLess />
                        }
                      >
                        {expandColleges ? 'Hide' : 'Show'} History
                      </Button>
                    </Typography>
                  </Grid>

                  {expandColleges &&
                    data.college_list &&
                    data.college_list.map((info) => (
                      <Grid item container xs={12} key={info.id}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={6}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.college}
                            readOnly
                            fullWidth
                            label="College"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.project || 'N/A'}
                            readOnly
                            fullWidth
                            label="Project"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.status}
                            readOnly
                            fullWidth
                            label="Status"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <TextField
                            value={info.award_year}
                            readOnly
                            fullWidth
                            label="Award Year"
                            variant="standard"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          paddingLeft={3}
                          paddingRight={3}
                          padding
                        >
                          <Divider />
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </Grid>
          </TabPanel>
          <TabPanel value={2} sx={{ width: '100%' }}>
            <Grid item xs={12} paddingLeft={3} paddingRight={3}>
              <Typography variant="h6">Notes</Typography>
            </Grid>
          </TabPanel>
          <TabPanel value={3} sx={{ width: '100%' }}>
            <Grid item xs={12} paddingLeft={3} paddingRight={3}>
              <Typography variant="h6">Update History</Typography>
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    );
  }
}
