import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Skeleton,
} from '@mui/material';
import { useApiGet } from '../utils/httpClient';
import { getTrainingGroups } from '../endpoints/training';

export default function TrainingGroupSelector({
  value,
  handleValueUpdate,
  label = 'Group',
}) {
  const { data } = useApiGet(['training-groups'], getTrainingGroups, {
    enabled: true,
    retry: 1,
  });

  return data ? (
    <FormControl fullWidth={true} variant="standard">
      <InputLabel id="formState">{label}</InputLabel>
      <Select
        labelId="training-group-select-label"
        id="training-group-select"
        variant="standard"
        size="small"
        fullWidth={true}
        value={value}
        label={label}
        onChange={(event) => handleValueUpdate(event.target.value)}
      >
        {data
          ?.sort((a, b) => a.order - b.order)
          .map((group) => (
            <MenuItem key={group.id} value={group.id}>
              {group.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  ) : (
    <Skeleton>
      <Select label="Step" />
    </Skeleton>
  );
}
