import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Modal,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

export default function TooltipWrapper({ message, id }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (message === null || message === undefined) {
    return null;
  }

  return (
    <React.Fragment>
      <IconButton
        size="small"
        color="info"
        sx={{ paddingBottom: 2 }}
        onClick={handleOpen}
        id={id}
      >
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
      <Modal
        id={id}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            {message.split('\n').map((item, key) => (
              <Grid item xs={12}>
                {key !== 0 && (
                  <Grid item xs={12} padding>
                    <Divider />
                  </Grid>
                )}
                {item.length > 0 && (
                  <Typography key={key} variant="body2" fontStyle={'italic'}>
                    {item}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
