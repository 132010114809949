import { request } from '../utils/httpClient';

export const getReportList = () =>
  request({
    url: '/reports/list',
    method: 'GET',
  });

export const getReportInfo = (data) =>
  request({
    url: `/reports/${data.reportId}`,
    method: 'GET',
  });

export const getReport = (data) => {
  let url = data.url;
  delete data.url;
  return request({
    url: `${url}`,
    method: 'POST',
    data: data,
  });
};

export const getReportExcel = (data) => {
  let url = data.url;
  delete data.url;
  return request({
    url: `${url}`,
    method: 'POST',
    data: data,
    responseType: 'blob',
  });
};
