import React from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemText,
  ListItem,
  ListItemIcon,
  List,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

export default function ReleaseNotes() {
  let releaseNotes = [
    {
      version: '0.0.40',
      releaseDate: '2024-12-10',
      notes: [
        'Donations to move to pull down for chapters.',
        'Donations Notifications to split name fields.',
      ],
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} paddingTop={5}>
        <Typography
          variant="h4"
          component="div"
          textAlign={'center'}
          color="primary"
        >
          Release Notes
        </Typography>
      </Grid>
      <Grid item container xs={12} padding>
        {releaseNotes.map((releaseNote, index) => (
          <Grid item xs={12} padding key={index}>
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                sx={{ backgroundColor: 'rgba(131, 1, 62, 0.2)' }}
                id="panel1-header"
              >
                <Typography variant="h6">
                  Version: {releaseNote.version} - Release Date:{' '}
                  {releaseNote.releaseDate}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ backgroundColor: 'rgba(131, 1, 62, 0.1)' }}
              >
                <List>
                  {releaseNote.notes.map((note, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemIcon>
                        <TrackChangesIcon />
                      </ListItemIcon>
                      <ListItemText>{note}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
