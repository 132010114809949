import React from 'react';
import { Grid, Typography } from '@mui/material';

const ReciprocityList = () => {
  return (
    <Grid>
      <Typography variant="h1">Reciprocity List</Typography>
    </Grid>
  );
};

export default ReciprocityList;
