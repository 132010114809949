import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { useApiGet } from '../../../utils/httpClient';
import { getDonationDetail } from '../../../endpoints/donations';
import PageHeader from '../../../Components/PageHeader';
import dayjs from 'dayjs';

export default function DonationDetail() {
  let { id } = useParams();
  const { data, isFetching } = useApiGet(
    ['donation', id],
    getDonationDetail,
    {
      enabled: true,
      retry: 1,
      refetchInterval: 15 * 60 * 1000, // The data will be refetched every 15 minutes
    },
    { id }
  );
  if (isFetching) {
    return <div>Loading...</div>;
  }
  if (data) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <PageHeader title="Donation Detail" />
        </Grid>
        <Grid item xs={12} sm={6} padding={3}>
          <Paper elevation={3} sx={{ height: 230 }}>
            <Grid item container xs={12}>
              <Grid item xs={12} padding>
                <Typography variant="h5">Donor Information</Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">
                  {data?.first_name} {data?.last_name}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">{data?.email}</Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">{data?.address1}</Typography>
              </Grid>
              {data?.address2 && (
                <Grid item xs={12} paddingLeft={5} paddingRight>
                  <Typography variant="body1">{data?.address2}</Typography>
                </Grid>
              )}
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">
                  {data.city}, {data.state} {data.postal_code}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight paddingBottom>
                <Typography variant="body1">{data.country}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} padding={3}>
          <Paper elevation={3} sx={{ height: 230 }}>
            <Grid item container xs={12}>
              <Grid item xs={12} padding>
                <Typography variant="h5">Donation Summary</Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">
                  Amount: ${data.amount.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">
                  Created At: {dayjs(data.created_at).format('MMM D, YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">
                  Payment Method: {data.payment_method}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">
                  Stripe Payment Id: {data.stripe_payment_id}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">
                  iMIS ID: {data.imis_id ? data.imis_id : 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight>
                <Typography variant="body1">
                  iMIS Acknowledgement Id:{' '}
                  {data.imis_acknowledgement_id
                    ? data.imis_acknowledgement_id
                    : 'Not Available'}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft={5} paddingRight paddingBottom>
                <Typography variant="body1">
                  iMIS Sync Date:{' '}
                  {data.imis_sync_date
                    ? dayjs(data.imis_sync_date).format('MMM D, YYYY')
                    : 'Not Synced'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} padding={3} paddingBottom={6}>
          <Paper elevation={3}>
            <Grid item container xs={12}>
              <Grid item xs={12} padding>
                <Typography variant="h5">Donation Lines</Typography>
              </Grid>
              {data.donation_lines.map((line, index) => (
                <Grid item container xs={12} key={line.id} padding>
                  <Grid item container xs={12} sm={6}>
                    <Grid item xs={12} paddingLeft={3} paddingRight>
                      <Typography variant="body1" fontWeight={'bold'}>
                        Line {index + 1} Details:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} paddingLeft={5} paddingRight>
                      <Typography variant="body1">
                        Amount: ${line.donation_amount.toFixed(2)}
                      </Typography>
                      <Typography variant="body1">
                        Project: {line.project_name}
                      </Typography>
                      {line.fund_name && (
                        <Typography variant="body1">
                          Fund: {line.fund_name}
                        </Typography>
                      )}
                      {line.behalf_of_name && (
                        <Typography variant="body1">
                          Behalf Of: {line.behalf_of_name}
                        </Typography>
                      )}
                      {line.behalf_of_type_name && (
                        <Typography variant="body1">
                          Behalf Of Type:{' '}
                          {line.behalf_of_type_name === 'honor'
                            ? 'In Honor Of'
                            : 'In Memory Of'}
                        </Typography>
                      )}
                      <Typography variant="body1">
                        Remain Anon: {line.remain_anonymous ? 'True' : 'False'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} sm={6}>
                    <Grid item xs={12} paddingLeft={3} paddingRight>
                      <Typography variant="body1" fontWeight={'bold'}>
                        Line {index + 1} Notifications:
                      </Typography>
                    </Grid>
                    {line.notifications.length === 0 && (
                      <Grid item xs={12} paddingLeft={5} paddingRight>
                        <Typography variant="body1">
                          No Notifications
                        </Typography>
                      </Grid>
                    )}
                    {line.notifications.map((notification, i) => (
                      <Grid
                        item
                        xs={12}
                        paddingLeft={5}
                        paddingRight
                        key={notification.id}
                      >
                        <Typography variant="body1" fontWeight={'bold'}>
                          Notification {i + 1}:{' '}
                        </Typography>
                        <Typography variant="body1">
                          Notification Method:{' '}
                          {notification.notification_type === 'mail'
                            ? 'Mail'
                            : 'Email'}
                        </Typography>
                        <Typography variant="body1">
                          {notification.full_name}
                        </Typography>
                        <Typography variant="body1">
                          {notification.email}
                        </Typography>
                        <Typography variant="body1">
                          {notification.address1}
                        </Typography>
                        {notification.address2 && (
                          <Typography variant="body1">
                            Address 2: {notification.address2}
                          </Typography>
                        )}
                        {notification.city && (
                          <Typography variant="body1">
                            {data.city}, {data.state} {data.postal_code}
                          </Typography>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
