import { request } from '../utils/httpClient';

export const getDonationFunds = () =>
  request({
    url: '/donation/funds',
    method: 'GET',
  });

export const getDonationProjects = () =>
  request({
    url: '/donation/projects',
    method: 'GET',
  });

export const getBehalfOfTypes = () =>
  request({
    url: '/donation/behalf_of_types',
    method: 'GET',
  });

export const createDonation = (data) =>
  request({
    url: '/donation',
    method: 'POST',
    data: data,
  });

export const createDonationLines = (data) =>
  request({
    url: '/donation/lines',
    method: 'POST',
    data: data,
  });

export const getInProgressDonation = (data) =>
  request({
    url: `/donation/inprogress/${data.id}`,
    method: 'GET',
  });

export const removeDonationLine = (data) =>
  request({
    url: `/donation/lines/${data.id}`,
    method: 'DELETE',
  });

export const removeDonationNotification = (data) =>
  request({
    url: `/donation/behalf_of/notification/${data.id}`,
    method: 'DELETE',
  });

export const getDonationList = (data) => {
  let url = `/donations/list?page_size=${data.rowsPerPage}&page=${data.page}`;
  if (data.search) {
    url += `&search=${data.search}`;
  }
  if (data.orderBy) {
    url += `&order_by=${data.orderBy}`;
  }
  if (data.order) {
    url += `&order=${data.order}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};

export const getDonationDetail = (data) =>
  request({
    url: `/donations/${data.id}`,
    method: 'GET',
  });

export const getDonationChapters = () =>
  request({
    url: '/donations/chapters',
    method: 'GET',
  });
