import React from 'react';
import { Grid, Typography } from '@mui/material';

const SPDChapterList = () => {
  return (
    <Grid>
      <Typography variant="h1">SPD Chapter List</Typography>
    </Grid>
  );
};

export default SPDChapterList;
