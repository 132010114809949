import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {},
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.veryLight,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function EnhancedTableHead({ headCells, order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            color="white"
            key={headCell.id}
            align={headCell.firstCell ? 'left' : 'right'}
            variant="head"
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              fontSize: 16,
              '&hover': {
                cursor: 'pointer',
                color: 'primary',
              },
              width: headCell.width,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function FakeLoadTable({ tableRowCount, headCells }) {
  return (
    <TableBody>
      {Array.from(new Array(tableRowCount)).map((_, index) => (
        <TableRow key={index}>
          {headCells.map((headCell) => (
            <TableCell align="left" padding="normal" key={headCell.id}>
              <Skeleton variant="text" width={headCell.width} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export default function PeoTable({
  data,
  isFetching = false,
  headCells,
  handleRowClick = null,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
}) {
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {isFetching && (
              <FakeLoadTable
                tableRowCount={rowsPerPage}
                headCells={headCells}
              />
            )}
            {!isFetching && (
              <TableBody>
                {data?.map((row, index) => {
                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleRowClick(event, row.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id + index}
                          align={headCell.firstCell ? 'left' : 'right'}
                          padding="normal"
                          sx={{ width: headCell.width }}
                        >
                          {row.hasOwnProperty(headCell.id)
                            ? row[headCell.id]
                            : ''}
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data?.length < rowsPerPage ? data.length : -1}
          rowsPerPage={data?.length < rowsPerPage ? data.length : rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          slotProps={{
            actions: {
              nextButton: { disabled: data?.length < rowsPerPage },
            },
          }}
        />
      </Paper>
    </Box>
  );
}
