import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function PCELandingPage() {
  return (
    <Grid>
      <Typography variant="h1">PCE Landing Page</Typography>
    </Grid>
  );
}
