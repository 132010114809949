import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { getDashboardData } from '../../endpoints/annualReports';
import { useApiGet } from '../../utils/httpClient';
import SPDDashboard from './SPDDashboard';
import LCDashboard from './LCDashboard';
import ExecDashboard from './ExecDashboard';
import PageHeader from '../../Components/PageHeader';
import SPDSelector from '../../Components/SPDSelector';
import RegionSelector from '../../Components/RegionSelector';

export default function Dashboard() {
  const [spdSelected, setSpdSelected] = useState('');
  const [regionSelected, setRegionSelected] = useState('all');

  const { data, isFetching } = useApiGet(
    ['dashboard', spdSelected, regionSelected],
    getDashboardData,
    {
      enabled: true,
      retry: 1,
    },
    { spd: spdSelected, region: regionSelected }
  );

  const dashboardToShow = (dashboardType) => {
    switch (dashboardType) {
      case 'local_chapter':
        return <LCDashboard data={data} />;
      case 'spd':
        return <SPDDashboard data={data} />;
      case 'exec':
        if (spdSelected) {
          return <SPDDashboard data={data} />;
        }
        return <ExecDashboard data={data} regionSelected={regionSelected} />;
      default:
        return null;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} padding>
        <PageHeader
          title="Dashboard"
          rightDom={
            data?.dashboardType === 'exec' ? (
              <SPDSelector
                handleSpdSelect={(spd) => setSpdSelected(spd)}
                spd={spdSelected}
                reportId="dashboard"
              />
            ) : null
          }
          leftDom={
            data?.dashboardType === 'exec' ? (
              <RegionSelector
                handleRegionChange={(spd) => setRegionSelected(spd)}
                formRegion={regionSelected}
                reportId="dashboard"
              />
            ) : null
          }
          showDashboardLink={false}
        />
      </Grid>
      {data && !isFetching && (
        <Grid container item xs={12}>
          {dashboardToShow(data.dashboardType)}
        </Grid>
      )}
    </Grid>
  );
}
