import React from 'react';
import { Autocomplete, TextField, Skeleton } from '@mui/material';
import { useApiGet } from '../utils/httpClient';
import { getDonationChapters } from '../endpoints/donations';

export default function DonationsChapterSelector({
  chapter,
  handleChapterSelect,
  label = 'Chapter',
  size = 'medium',
}) {
  const { data: chapterList } = useApiGet(
    ['donations-chapter'],
    getDonationChapters,
    {
      enabled: true,
      retry: 1,
    }
  );

  const onChapterSelect = (chapterId) => {
    handleChapterSelect(chapterId);
  };

  console.log(chapter);

  return chapterList ? (
    <Autocomplete
      options={chapterList}
      variant="standard"
      size={size}
      clearOnBlur={true}
      getOptionLabel={(option) => option.name}
      value={
        chapterList.find((chapterObject) => chapterObject.id === chapter) ||
        null
      }
      onChange={(event, newValue) =>
        onChapterSelect(newValue ? newValue.id : null)
      }
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  ) : (
    <Skeleton>
      <TextField label={label} />
    </Skeleton>
  );
}
