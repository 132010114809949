import React from 'react';
import { Select, Autocomplete, TextField, Skeleton } from '@mui/material';
import { useApiGet } from '../utils/httpClient';
import { getTrainingCategory } from '../endpoints/training';

export default function TrainingCategorySelector({
  value,
  handleValueChange,
  label = 'Category',
}) {
  const { data } = useApiGet(['training-category'], getTrainingCategory, {
    enabled: true,
    retry: 1,
  });

  const onValueChange = (newValue) => {
    handleValueChange(newValue);
  };

  return data ? (
    <Autocomplete
      multiple
      variant="standard"
      size="small"
      id="tags-standard"
      options={data}
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={(event, newValue) => onValueChange(newValue)}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} />
      )}
    />
  ) : (
    <Skeleton>
      <Select label="Step" />
    </Skeleton>
  );
}
