import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Box, Typography, Link } from '@mui/material';

import logo from './assets/PEO_InformalLogo_Black.png';
import { applications } from './utils/variables';

const StyledRootBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

const StyledFooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: theme.spacing(0),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}));

const StyledFooterLink = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  color: theme.palette.secondary.contrastText,
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
  },
}));

const StyledFooterCopy = styled(Box)(({ theme }) => ({
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.background.emphasis,
}));

const StyledCopy = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
}));

export default function Footer() {
  const showAllLinks = localStorage.getItem('user_id') ? true : false;

  const showFormLinks = localStorage.getItem('apps')
    ? localStorage.getItem('apps').includes(applications.ExecAppBar)
      ? true
      : false
    : false;

  return (
    <Box
      sx={{
        width: '100%',
        height: 150,
        position: 'absolute', // "fixed" for a sticky footer, "absolute" for a footer that scrolls with the page
        bottom: 0,
        backgroundColor: '#9C1555',
      }}
    >
      <Grid container>
        <StyledGrid item xs={12}>
          <StyledRootBox
            pt={3}
            pb={2}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
          >
            <Link href="/" color="inherit" underline="none">
              <Box
                component="img"
                sx={{
                  height: 'auto',
                  width: 'auto',
                  paddingLeft: 2,
                  maxWidth: 100,
                }}
                alt="Logo."
                src={logo}
              />
            </Link>
            {showAllLinks && (
              <StyledFooterBox component="nav" ml="auto">
                <StyledFooterLink href="/" variant="body1">
                  Dashboard
                </StyledFooterLink>
                {showFormLinks && (
                  <StyledFooterLink href="/roster" variant="body1">
                    Roster / Dues
                  </StyledFooterLink>
                )}
                {showFormLinks && (
                  <StyledFooterLink href="/irs" variant="body1">
                    IRS-LC
                  </StyledFooterLink>
                )}
                <StyledFooterLink href="/reports" variant="body1">
                  Reports
                </StyledFooterLink>
              </StyledFooterBox>
            )}
          </StyledRootBox>
          <StyledFooterCopy
            pt={1}
            pb={2}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
          >
            <StyledCopy
              color="textSecondary"
              component="p"
              variant="caption"
              gutterBottom={false}
            >
              {`© ${new Date().getFullYear()} PEO International. All rights reserved.`}
            </StyledCopy>
          </StyledFooterCopy>
        </StyledGrid>
      </Grid>
    </Box>
  );
}
