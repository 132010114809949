export const toolTips = {
  c2d10e6c39bf4b84b728aa0e6324746e:
    'Ledger – Receipts – Dues, Fees, Assessments\nExcel report – Line 1',
  '77bdefb130884ca2af352ca3e80f8ee3':
    'Ledger – Receipts – Gifts/Bequests Received\nExcel report – Line 2',
  eafd00006a064224966088c3b59384f0:
    'A bequest is a gift left to your chapter in someone’s will or trust to be distributed after their passing.  If your chapter did receive a bequest of $5,000 or more, you will be required to upload documentation before you can submit the online form.',
  '4f19da96a310482ab27cc4c85aa919de':
    'Ledger – Receipts – Checking Acct Interest (plus interest recorded for other accounts)\nExcel report – Line 3 (plus interest recorded for other accounts)',
  '4bab454f0fc04da9baf0592c10016618':
    'Ledger – Receipts – Dividends\nExcel report – Line 4',
  '4c50bf2c5451427e8bc487dafaa70060':
    'Ledger – Receipts – Goods Sold\nExcel report – Line 5\nHere you will complete all Goods Sold information in one place (receipts, disbursements and the tax question). The form will automatically put your Goods Sold and Cost of Goods Sold amounts on the proper lines.\nExamples:\n\t•	Plant or nut sales\n\t•	Jewelry or apron sales, where the product was made by the chapter or another individual\n\t•	Scrip programs, where the chapter purchases gift cards from retailers (either physically or online) at a discount and sells them to members or others at face value.  If the chapter is participating in a program where members are purchasing the cards directly and the chapter only receives the profit, it can be recorded as a Ways & Means receipt.\n\t•	Garage sales (even if the items were donated)',
  b4b75e42cc724f39a3adf85c6b4d3bcd:
    'Ledger – Receipts – Ways & Means\nExcel report – Line 6\nIf you used one of your “Other” lines to record income for a Ways & Means fundraiser, that amount should be included here rather than listed on an “Other” line.\nExamples:\n\t•	Funds received at meetings through the Brag Basket\n\t•	Fundraising luncheons\n\t•	Event-based experiences such as a craft night, concert or seminar\n\t•	Auctions and raffles\n\t•	Scrip program where members purchase gift cards (physical or e-cards) directly and the chapter just receives a percentage from the company.',
  '27b665b2aead4f2f915339640601d38c':
    'Ledger – Receipts – Refunds\nExcel report – Line 7',
  '1885ea03cb094fe8847ba269a8b07c61':
    'Ledger – Receipts – Bed and Breakfast\nExcel report – Line 8 (old version – Line 9)\nHere you will complete all Bed and Breakfast information in one place (receipts, disbursements and the tax question). The form will automatically put your receipts and disbursements on the proper lines.',
  '2aa92f4b9d1140e7b4160c0ab4220f08':
    'Ledger – Receipts – Column(s) you manually added\nExcel report – Lines 9 & 10 (old version – Line 8)',
  '14f5fd664fb64f80ab2d76c3dd92b9ec':
    'Ledger – Disbursements – Dues, Fees, Assessments\nExcel report – Line 13 (old version – Line 12)\nEnter the amount of the dues check just written and dated Febuary 28/29.',
  '04eacf562cb24a2bad38630a58fe1a2d':
    "Ledger – Disbursements – Dues, Fees, Assessments\nExcel report – Line 13 (old version – Line 12)\nRemaining amount after subtracting Question 1. This may include reciprocity dues, adjustments to last year's dues after the annual report was submitted, etc.",
  ef17ef2ed43145618c34fcd2f25926d3:
    'Ledger – Disbursements – Donations to International Chapter Projects\nExcel report – Line 14 (old version – Line 13)\nTransfer funds are set up in the P.E.O. Foundation with distributions going to International Chapter projects rather than to an individual.',
  '58d97ad6560245bdb4eb7d3a64c7a87f':
    'Ledger – Disbursements – Donation to State Projects\nExcel report – Line 15 (old version – Line 14)\nThis includes donations to state or district chapter funds held in the Foundation.',
  d64c8ca603e146899f5394bd25f819fa:
    'Ledger – Disbursements – “Other” column you may have designated for local projects\nExcel file – Line 16 (old version - If you used an “Other” column for local projects, the amount will be on Line 15 or 21)\nThis total includes donations to local chapter scholarship funds held in the P.E.O. Foundation or held in the chapter treasury.',
  '7ffbeb17f21140a4b0140787e4ac3821':
    'Ledger - Disbursements – Convention Expense\nExcel report – Line 17 (old version – Line 16)',
  c07551a1eccc4168b74f43ee275ac5e0:
    'Ledger – Disbursement – Other Operating Exp.\nExcel report – Line 18 (old version – Line 17)',
  b21d13cf442743128759438d57d33e05:
    'Ledger – Disbursement – any “other” column you named that doesn’t fit into another category\nExcel report – Line 19 (old version - If you used an “other” line, amount will be on Line 15 or 21',
  ecea95f923634856af8800ac1c026bca:
    'Ledger – Disbursement – Cost of Goods Sold\nExcel report – Line 20 (old version – Line 18).',
  d94ef3e0e30a45c6a15134b5521a6387:
    'Ledger – Disbursement – Cost of Bed and Breakfast\nExcel report – Line 21 (old version – Line 19).',
};
