import { React, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import MapIcon from '@mui/icons-material/Map';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Collapse from '@mui/material/Collapse';
import { Typography } from '@mui/material';
import logo from './assets/PEO_InformalLogo_Color.png';
import Grid from '@mui/material/Grid';

export default function MenuDrawer({ toggleDrawer, open, logout }) {
  const [openChapters, setOpenChapters] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);

  const handleRouteSelection = (route) => {
    toggleDrawer(false);
    switch (route) {
      case 'my-chapter':
        window.location.href = '/my-chapter';
        break;
      case 'local-chapters':
        window.location.href = '/local-chapters';
        break;
      case 'spd-chapters':
        window.location.href = '/spd-chapters';
        break;
      case 'reciprocities':
        window.location.href = '/reciprocity';
        break;
      case 'groups':
        window.location.href = '/groups';
        break;
      case 'member-search':
        window.location.href = '/user';
        break;
      case 'bnb':
        window.location.href = '/bnb';
        break;
      case 'tlc':
        window.location.href = '/tlc';
        break;
      case 'elf':
        window.location.href = '/projects/elf';
        break;
      case 'ips':
        window.location.href = '/projects/ips';
        break;
      case 'pce':
        window.location.href = '/projects/pce';
        break;
      case 'psa':
        window.location.href = '/projects/psa';
        break;
      case 'star':
        window.location.href = '/projects/star';
        break;
      case 'foundation':
        window.location.href = '/foundation';
        break;
      case 'donations':
        window.location.href = '/internal/donations';
        break;
      case 'shop':
        window.location.href = '/shop';
        break;
      case 'my-profile':
        window.location.href = '/my-profile';
        break;
      default:
        window.location.href = '/';
        break;
    }
  };

  const handleLogOut = (e) => {
    logout(e);
  };

  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation">
      <Grid container justifyContent="center" alignItems="center">
        <Box
          component="img"
          sx={{
            height: 35,
            width: 74,
          }}
          alt="Logo."
          src={logo}
        />
        <Typography
          variant="h6"
          color="primary"
          padding={2}
          fontWeight={'bold'}
        >
          PEO Hub
        </Typography>
      </Grid>
      <List>
        <ListItem key={'my-chapter'} disablePadding>
          <ListItemButton onClick={() => handleRouteSelection('my-chapter')}>
            <ListItemIcon>
              <MyLocationIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="primary" fontWeight={'bold'}>
                  My Chapter
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem key={'test'} disablePadding>
          <ListItemButton onClick={() => setOpenChapters(!openChapters)}>
            <ListItemIcon>
              <MapIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="primary" fontWeight={'bold'}>
                  Chapters / Groups
                </Typography>
              }
            />
            {openChapters ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={openChapters} timeout="auto" unmountOnExit>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => handleRouteSelection('local-chapters')}
            >
              <ListItemText
                primary={
                  <Typography color="primary" fontWeight={'bold'}>
                    Local Chapters
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => handleRouteSelection('spd-chapters')}
            >
              <ListItemText
                primary={
                  <Typography color="primary" fontWeight={'bold'}>
                    SPD Chapters
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => handleRouteSelection('reciprocities')}
            >
              <ListItemText
                primary={
                  <Typography color="primary" fontWeight={'bold'}>
                    Reciprocities
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => handleRouteSelection('groups')}
            >
              <ListItemText
                primary={
                  <Typography color="primary" fontWeight={'bold'}>
                    Groups
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </Collapse>
        <ListItem key={'test'} disablePadding>
          <ListItemButton onClick={() => setResourcesOpen(!resourcesOpen)}>
            <ListItemIcon>
              <PeopleIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="primary" fontWeight={'bold'}>
                  Membership Resources
                </Typography>
              }
            />
            {resourcesOpen ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={resourcesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('member-search')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      Member Search
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('bnb')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      Bed and Breakfast
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('tlc')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      TLC
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem key={'projects'} disablePadding>
          <ListItemButton onClick={() => setProjectsOpen(!projectsOpen)}>
            <ListItemIcon>
              <AccountBalanceIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="primary" fontWeight={'bold'}>
                  Projects / Foundation
                </Typography>
              }
            />
            {projectsOpen ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={projectsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('elf')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      ELF
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('ips')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      IPS
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('pce')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      PCE
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('psa')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      PSA
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('star')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      Star
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('foundation')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      Foundation
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleRouteSelection('donations')}
              >
                <ListItemText
                  primary={
                    <Typography color="primary" fontWeight={'bold'}>
                      Donations
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem key={'profile'} disablePadding>
          <ListItemButton onClick={() => handleRouteSelection('shop')}>
            <ListItemIcon>
              <ShoppingCartIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="primary" fontWeight={'bold'}>
                  Shop
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={'profile'} disablePadding>
          <ListItemButton onClick={() => handleRouteSelection('my-profile')}>
            <ListItemIcon>
              <AccountCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="primary" fontWeight={'bold'}>
                  My Profile
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem key={'logout'} disablePadding>
          <ListItemButton onClick={handleLogOut}>
            <ListItemIcon>
              <LogoutIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="primary" fontWeight={'bold'}>
                  Logout
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Drawer open={open} onClose={() => toggleDrawer(false)}>
      {DrawerList}
    </Drawer>
  );
}
