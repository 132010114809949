import { request } from '../utils/httpClient';

export const getDocuments = (data) => {
  let url = '/documents';
  if (data.category) {
    url += `?category=${data.category}`;
  }
  if (data.search) {
    url += `&search=${data.search}`;
  }
  if (data.type) {
    url += `&type=${data.type}`;
  }
  return request({
    url: `${url}`,
    method: 'GET',
  });
};

export const getIRSDocument = (data) => {
  return request({
    url: `/document/irs_form/${data.id}`,
    method: 'GET',
  });
};

export const deleteDocument = (data) => {
  return request({
    url: `/document/${data.id}`,
    method: 'DELETE',
  });
};

export const downloadDocument = (data) => {
  return request({
    url: `/document/${data.id}/download`,
    method: 'GET',
  });
};
