import { request } from '../utils/httpClient';

export const getPaymentIntent = (data) =>
  request({
    url: '/payments/intent',
    method: 'POST',
    data: data,
  });

export const updatePaymentStatus = (data) =>
  request({
    url: '/payments/intent',
    method: 'PATCH',
    data: data,
  });
