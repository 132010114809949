import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import { useApiGet } from '../../utils/httpClient';
import { getUsers } from '../../endpoints/users';
import { useCallback } from 'react';
import PageHeader from '../../Components/PageHeader';
import PeoTable from '../../Components/PeoTable';

const headCells = [
  {
    id: 'first_name',
    firstCell: true,
    label: 'First Name',
    width: '25%',
  },
  {
    id: 'last_name',
    label: 'Last Name',
    width: '25%',
  },
  {
    id: 'is_member',
    label: 'Is Member',
    width: '15%',
  },
  {
    id: 'chapter_abbreviation',
    label: 'Chapter',
    width: '25%',
  },
  {
    id: 'imis_id',
    label: 'iMIS ID',
    width: '10%',
  },
];

export default function UserList() {
  let [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (key, value) => {
      if (!value || value === '') {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [delaySearch, setDelaySearch] = useState(
    searchParams.get('search') || ''
  );
  const [chapter, setChapter] = useState(searchParams.get('chapter') || '');

  // need up update search params when search changes
  useEffect(() => {
    updateSearchParams('search', search);
  }, [search, updateSearchParams]);

  const { data, isFetching } = useApiGet(
    ['users', rowsPerPage, page, orderBy, order, search, chapter],
    getUsers,
    {
      enabled: true,
      retry: 1,
    },
    {
      rowsPerPage,
      page,
      orderBy,
      order,
      search,
      chapter,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(delaySearch);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [delaySearch]);

  const handleClick = (event, id) => {
    window.location.href = `/user/${id}`;
  };

  return (
    <Grid container paddingLeft={2} paddingRight={2}>
      <Grid item xs={12}>
        <PageHeader title="Users" />
      </Grid>
      <Grid container>
        <Grid item xs={4} paddingRight={2} paddingBottom={3}>
          <TextField
            label="Search"
            id="search-field"
            size="small"
            value={delaySearch}
            fullWidth={true}
            onChange={(event) => setDelaySearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <PeoTable
        data={data}
        isFetching={isFetching}
        headCells={headCells}
        handleRowClick={handleClick}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
    </Grid>
  );
}
