export const formatCurrency = (value) => {
  if (value === null || value === undefined || isNaN(value) || value === '') {
    return value;
  }
  try {
    value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  } catch (e) {
    console.log(value);
    return value;
  }
  return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

export const formatFinancial = (value) => {
  // This function formats a number as a currency string with no leading $ sign
  if (value === null || value === undefined || isNaN(value) || value === '') {
    return value;
  }
  value = (value * 100) / 100;
  return `${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};
