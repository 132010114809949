import React, { useEffect, useState } from 'react';
import { formatFinancial } from '../utils/formatters';
import { TextField, InputAdornment } from '@mui/material';

export default function ValidatedTextField({
  label,
  validator,
  onChange,
  valueRequired = false,
  value,
  startAdornment = '',
  errorOverride = null,
  number = false,
  disabled = false,
  financial = false,
  variant = 'outlined',
  size = 'small',
  focus = false,
}) {
  const [helperText, setHelperText] = useState('');
  const [overrideValue, setOverrideValue] = useState('');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (financial && value && value !== 0 && !focused) {
      setOverrideValue(formatFinancial(value));
    } else {
      setOverrideValue(value);
    }
  }, [value, financial, focused]);

  const handleChange = (e) => {
    let newValue = e.target.value;
    let helperText = '';
    if (validator !== null) {
      if (valueRequired) {
        helperText = validator(newValue);
      } else if (e.target.value !== '') {
        helperText = validator(newValue);
      }
    }
    setHelperText(helperText);
    onChange(!helperText, newValue);
  };

  const handleFocus = () => {
    setFocused(true);
    if (financial) {
      setOverrideValue(value);
    }
  };

  return (
    <TextField
      label={label ? label : ''}
      size={size}
      inputRef={focus ? (input) => input && input.focus() : null}
      fullWidth
      variant={variant}
      multiline
      required={valueRequired}
      maxRows={4}
      onFocus={() => handleFocus()}
      onBlur={() => setFocused(false)}
      disabled={disabled}
      inputProps={{ style: { textAlign: number ? 'right' : 'left' } }}
      InputProps={
        startAdornment
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
            }
          : {}
      }
      error={!!helperText || !!errorOverride}
      helperText={helperText || errorOverride}
      onChange={handleChange}
      value={overrideValue === 0 ? null : overrideValue}
      placeholder={value === 0 ? 0 : null}
    />
  );
}
