import React from 'react';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useApiGet } from '../utils/httpClient';
import { getStateProvinces, stateProvinces } from '../endpoints/annualReports';

export default function StateProvinceSelector({
  handleChange,
  all = false,
  size = 'small',
  error = null,
  errorMessage = null,
  value = null,
  required = false,
  irs = false,
}) {
  const { data: userStateProvinces } = useApiGet(
    ['user-states-provinces'],
    getStateProvinces,
    {
      enabled: !all,
      retry: 1,
    }
  );

  const { data: allStateProvinces } = useApiGet(
    ['all-states-provinces'],
    stateProvinces,
    {
      enabled: all,
      retry: 1,
    }
  );

  let label = irs ? 'State / District' : 'State / Province / District';
  if (required) {
    label += ' *';
  }

  return userStateProvinces || allStateProvinces ? (
    <Autocomplete
      size={size}
      onChange={(event, newValue) => handleChange(newValue)}
      getOptionLabel={(option) => option.label}
      variant="standard"
      value={
        value
          ? all
            ? allStateProvinces.find((option) => option.value === value)
            : userStateProvinces.find((option) => option.value === value)
          : null
      }
      label={label}
      options={all ? allStateProvinces : userStateProvinces}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={errorMessage}
        />
      )}
    />
  ) : (
    <Skeleton>
      <TextField
        size={size}
        label="State / Province / District"
        error={error}
        helperText={errorMessage}
      />
    </Skeleton>
  );
}
