import React, { useState, useEffect } from 'react';

import { Grid } from '@mui/material';
import SPDSelector from '../../Components/SPDSelector';
import ChapterSelector from '../../Components/ChapterSelector';

function SupportedFilters({
  filterKey,
  filterValue,
  updateFilters,
  dependantKey,
  reportId,
}) {
  switch (filterKey) {
    case 'spd_id':
      return (
        <SPDSelector
          spd={filterValue}
          reportId={reportId}
          handleSpdSelect={(value) => updateFilters(filterKey, value)}
        />
      );
    case 'chapter_id':
      return (
        <ChapterSelector
          chapter={filterValue}
          spd={dependantKey}
          reportId={reportId}
          handleChapterSelect={(value) => updateFilters(filterKey, value)}
        />
      );
    default:
      return null;
  }
}

export default function Filters({ filters, updateFilters, reportId }) {
  const [xsmallGrid, setXSmallGrid] = useState(12);
  const [smallGrid, setSmallGrid] = useState(6);
  const [mediumGrid, setMediumGrid] = useState(4);
  const [largeGrid, setLargeGrid] = useState(3);

  useEffect(() => {
    if (filters.length === 1) {
      setXSmallGrid(12);
      setSmallGrid(12);
      setMediumGrid(12);
      setLargeGrid(12);
    } else if (filters.length === 2) {
      setXSmallGrid(12);
      setSmallGrid(6);
      setMediumGrid(6);
      setLargeGrid(6);
    } else if (filters.length === 3) {
      setXSmallGrid(12);
      setSmallGrid(6);
      setMediumGrid(4);
      setLargeGrid(4);
    } else if (filters.length === 4) {
      setXSmallGrid(12);
      setSmallGrid(6);
      setMediumGrid(4);
      setLargeGrid(3);
    } else {
      setXSmallGrid(12);
      setSmallGrid(6);
      setMediumGrid(4);
      setLargeGrid(3);
    }
  }, [filters.length]);

  const handleUpdateFilter = (filterKey, filterValue) => {
    let newFilters = { ...filters };
    newFilters[filterKey] = filterValue;
    if (
      filterKey === 'spd_id' &&
      filterValue === null &&
      Object.keys(filters).includes('chapter_id')
    ) {
      newFilters['chapter_id'] = null;
    }
    updateFilters(newFilters);
  };

  return (
    <Grid container>
      {Object.keys(filters).map((filterKey, index) => (
        <Grid
          item
          xs={xsmallGrid}
          sm={smallGrid}
          md={mediumGrid}
          lg={largeGrid}
          key={index}
          padding
        >
          <SupportedFilters
            filterKey={filterKey}
            filterValue={filters[filterKey]}
            reportId={reportId}
            updateFilters={(filterKey, value) =>
              handleUpdateFilter(filterKey, value)
            }
            dependantKey={
              filterKey === 'chapter_id' &&
              Object.keys(filters).includes('spd_id')
                ? filters['spd_id']
                : null
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}
