import { request } from '../utils/httpClient';

export const getTrainingTopics = () =>
  request({
    url: '/training/topic',
    method: 'GET',
  });

export const getTrainingCategory = () =>
  request({
    url: '/training/category',
    method: 'GET',
  });

export const getTrainingRoles = () =>
  request({
    url: '/training/role',
    method: 'GET',
  });

export const getTrainingGroups = () =>
  request({
    url: '/training/group',
    method: 'GET',
  });

export const getTrainingsInGroup = (data) =>
  request({
    url: `/training/group/${data.groupSelected}`,
    method: 'GET',
  });

export const getTrainingList = (data) => {
  let url = '/training/list?page_size=100&page=1';
  if (data.search) {
    url += `&search=${data.search}`;
  }
  if (data.roles.length > 0) {
    let tempRoles = [];
    data.roles.forEach((role) => {
      tempRoles.push(role.id);
    });
    url += `&roles=${tempRoles}`;
  }
  if (data.categories.length > 0) {
    let tempCategories = [];
    data.categories.forEach((category) => {
      tempCategories.push(category.id);
    });
    url += `&categories=${tempCategories}`;
  }
  if (data.topics.length > 0) {
    let tempTopics = [];
    data.topics.forEach((topic) => {
      tempTopics.push(topic.id);
    });
    url += `&topics=${tempTopics}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};

export const getTrainingDetail = (data) =>
  request({
    url: `/training/${data.id}`,
    method: 'GET',
  });

export const completeTraining = (data) =>
  request({
    url: `/training/${data.id}/complete`,
    method: 'POST',
  });

export const submitTrainingAPI = (data) =>
  request({
    url: '/training',
    method: 'POST',
    data: data,
  });
