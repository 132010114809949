import React from 'react';

import { FormControl, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getReportList } from '../../endpoints/reports';

export default function ReportSelector({ report, handleReportSelect }) {
  const { data: reportList } = useApiGet(['reports'], getReportList, {
    enabled: true,
    retry: 1,
  });

  const onReportSelect = (reportId) => {
    let report = reportList.find((report) => report.id === reportId);
    handleReportSelect(report);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {reportList && (
          <FormControl fullWidth>
            <InputLabel id="report-select-label">Report</InputLabel>
            <Select
              labelId="report-select-label"
              id="report-select"
              size="small"
              variant="standard"
              value={report}
              label="Report"
              onChange={(event) => onReportSelect(event.target.value)}
            >
              {reportList?.map((report) => (
                <MenuItem key={report.id} value={report.id}>
                  {report.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
}
