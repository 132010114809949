import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getRosterForms } from '../../endpoints/annualReports';
import FormStateSelector from '../../Components/FormStateSelector';
import StateProvinceSelector from '../../Components/StateProvinceSelector';
import { useCallback } from 'react';
import PageHeader from '../../Components/PageHeader';
import PeoTable from '../../Components/PeoTable';

const headCells = [
  {
    id: 'chapter_name',
    firstCell: true,
    label: 'Chapter',
    width: '10%',
  },
  {
    id: 'chapter_state',
    label: 'State / Province / District',
    width: '30%',
  },
  {
    id: 'state',
    label: 'Step',
    width: '30%',
  },
  {
    id: 'assigned_to',
    label: 'Assigned To',
    width: '30%',
  },
];

export default function Roster() {
  let [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (key, value) => {
      if (!value || value === '') {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [formStatus, setFormStatus] = useState(
    searchParams.get('formStatus') ? searchParams.get('formStatus') : 'all'
  );
  const [stateProvince, setStateProvince] = useState(
    searchParams.get('stateProvince') || ''
  );
  const [disbanded, setDisbanded] = useState(
    searchParams.get('disbanded') ? searchParams.get('disbanded') : 'all'
  );
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [delaySearch, setDelaySearch] = useState(
    searchParams.get('search') || ''
  );

  // need up update search params when search changes
  useEffect(() => {
    updateSearchParams('search', search);
  }, [search, updateSearchParams]);
  // need up update params when disbanded changes
  useEffect(() => {
    updateSearchParams('disbanded', disbanded);
  }, [disbanded, updateSearchParams]);
  // need up update params when formStatus changes
  useEffect(() => {
    updateSearchParams('formStatus', formStatus);
  }, [formStatus, updateSearchParams]);
  // need up update params when stateProvince changes
  useEffect(() => {
    updateSearchParams('stateProvince', stateProvince);
  }, [stateProvince, updateSearchParams]);

  const { data, isFetching } = useApiGet(
    [
      'roster_forms',
      rowsPerPage,
      page,
      stateProvince,
      formStatus,
      disbanded,
      orderBy,
      order,
      search,
    ],
    getRosterForms,
    {
      enabled: true,
      retry: 1,
    },
    {
      rowsPerPage,
      page,
      stateProvince,
      formStatus,
      disbanded,
      orderBy,
      order,
      search,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(delaySearch);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [delaySearch]);

  const handleClick = (event, id) => {
    window.location.href = `/roster/${id}`;
  };

  const handleChange = (event) => {
    if (!event) {
      setStateProvince('');
    } else {
      setStateProvince(event.value);
    }
  };

  const handleStateChange = (stateId) => {
    if (stateId !== 0) {
      setFormStatus(stateId);
    }
  };

  return (
    <Grid container paddingLeft={2} paddingRight={2}>
      <Grid item xs={12}>
        <PageHeader title="Roster Dues Forms" />
      </Grid>
      <Grid container>
        <Grid item xs={4} paddingRight={2} paddingBottom={3}>
          <TextField
            label="Search"
            id="search-field"
            size="small"
            value={delaySearch}
            fullWidth={true}
            onChange={(event) => setDelaySearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3} paddingRight={2}>
          <StateProvinceSelector
            value={stateProvince}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={3} paddingRight={2}>
          <FormStateSelector
            formState={formStatus}
            handleStateChange={handleStateChange}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth={true} variant="outlined" size="small">
            <InputLabel id="disbanded">Disbanded</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              fullWidth={true}
              value={disbanded}
              input={<OutlinedInput label="Disbanded" />}
              label="Disbanded"
              onChange={(event) => setDisbanded(event.target.value)}
            >
              <MenuItem key={'all'} value={'all'}>
                {'All'}
              </MenuItem>
              <MenuItem key={'true'} value={'true'}>
                {'True'}
              </MenuItem>
              <MenuItem key={'false'} value={'false'}>
                {'False'}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <PeoTable
        data={data}
        isFetching={isFetching}
        headCells={headCells}
        handleRowClick={handleClick}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
    </Grid>
  );
}
