import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, ButtonGroup, Divider, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import QuestionLine from '../../Components/QuestionLine';
import { irsSubmitAnswers } from '../../endpoints/annualReports';
import { useApiSend } from '../../utils/httpClient';
import { formatCurrency } from '../../utils/formatters';
import { disbursementQuestions } from '../../utils/variables';

export default function Disbursements({
  questions,
  otherQuestions,
  currentDuesTotal,
  refetch,
  editable,
  alertOpen,
  alertMessage,
  alertSeverity,
  userEditing,
  updateUserEditing,
}) {
  const { id } = useParams();
  const formValid = useRef({
    question1: true,
    question2: true,
    question3: true,
    question4: true,
    question5: true,
    question6: true,
    question9: true,
    question10: true,
    subQuestion3: true,
    subQuestion4: true,
    question0: true,
  });
  const [question0, setQuestion0] = useState(null);
  const [question0Error, setQuestion0Error] = useState(null);
  const [question1, setQuestion1] = useState(null);
  const [question2, setQuestion2] = useState(null);
  const [subQuestion3, setSubQuestion3] = useState(null);
  const [subQuestion3Error, setSubQuestion3Error] = useState(null);
  const [subQuestion4, setSubQuestion4] = useState(null);
  const [subQuestion4Error, setSubQuestion4Error] = useState(null);
  const [question3, setQuestion3] = useState(null);
  const [question4, setQuestion4] = useState(null);
  const [question5, setQuestion5] = useState(null);
  const [question5Error, setQuestion5Error] = useState(null);
  const [question6, setQuestion6] = useState(null);
  const [question9, setQuestion9] = useState(null);
  const [question10Text, setQuestion10Text] = useState(null);
  const [question10Number, setQuestion10Number] = useState(null);
  const [question10Error, setQuestion10Error] = useState(null);
  const [answersSet, setAnswersSet] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { mutate } = useApiSend(
    irsSubmitAnswers,
    (data) => {
      handleScrollToTop();
      alertMessage('Disbursements successfully updated.');
      alertSeverity('success');
      alertOpen();
      refetch();
    },
    (error) => {
      console.log(error);
      alertMessage(
        'Error updating Disbursements. If the problem persists, please contact support.'
      );
      alertSeverity('error');
      alertOpen();
    }
  );

  const setAnswers = useCallback(() => {
    questions.forEach((answers) => {
      if (answers.question_id === disbursementQuestions.question1) {
        setQuestion1(answers.answer);
      } else if (answers.question_id === disbursementQuestions.question2) {
        setQuestion2(answers.answer);
      } else if (answers.question_id === disbursementQuestions.subQuestion3) {
        setSubQuestion3(answers.answer);
      } else if (answers.question_id === disbursementQuestions.subQuestion4) {
        setSubQuestion4(answers.answer);
      } else if (answers.question_id === disbursementQuestions.question3) {
        setQuestion3(answers.answer);
      } else if (answers.question_id === disbursementQuestions.question4) {
        setQuestion4(answers.answer);
      } else if (answers.question_id === disbursementQuestions.question5) {
        setQuestion5(answers.answer);
      } else if (answers.question_id === disbursementQuestions.question6) {
        setQuestion6(answers.answer);
      } else if (answers.question_id === disbursementQuestions.question9) {
        setQuestion9(answers.answer);
      } else if (answers.question_id === disbursementQuestions.question10) {
        setQuestion10Number(answers.answer_2);
        setQuestion10Text(answers.answer);
      } else if (answers.question_id === disbursementQuestions.question0) {
        setQuestion0(answers.answer);
      }
    });
  }, [questions]);

  useEffect(() => {
    setAnswers();
    setAnswersSet(true);
  }, [questions, setAnswers]);

  const answersUpdated = useCallback(() => {
    let answersUpdated = false;
    questions.forEach((answers) => {
      if (
        answers.question_id === disbursementQuestions.question1 &&
        question1 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.question2 &&
        question2 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.subQuestion3 &&
        subQuestion3 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.subQuestion4 &&
        subQuestion4 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.question3 &&
        question3 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.question4 &&
        question4 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.question5 &&
        question5 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.question6 &&
        question6 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.question9 &&
        question9 !== answers.answer
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.question10 &&
        (question10Number !== answers.answer_2 ||
          question10Text !== answers.answer)
      ) {
        answersUpdated = true;
      } else if (
        answers.question_id === disbursementQuestions.question0 &&
        question0 !== answers.answer
      ) {
        answersUpdated = true;
      }
    });
    return answersUpdated;
  }, [
    question1,
    question2,
    subQuestion3,
    subQuestion4,
    question3,
    question4,
    question5,
    question6,
    question9,
    question10Text,
    question10Number,
    question0,
    questions,
  ]);

  useEffect(() => {
    if (answersUpdated() && answersSet && !userEditing) {
      updateUserEditing(true);
    }
  }, [answersUpdated, updateUserEditing, answersSet, userEditing]);

  const updateSubQuestion3 = (value) => {
    setSubQuestion3(value);
    setSubQuestion3Error(null);
    if (!value) {
      setSubQuestion4(null);
    }
  };

  const updateQuestion5 = (value) => {
    setQuestion4(value);
    if (subQuestion4 && parseFloat(subQuestion4) > parseFloat(value)) {
      setSubQuestion4Error(
        `Amount must be less than or equal question 5 (Currently: ${formatCurrency(parseFloat(value || 0))})`
      );
    } else {
      setSubQuestion4Error(null);
    }
  };

  const updateSubQuestion4 = (value) => {
    setSubQuestion4(value);
    if (value && parseFloat(value) <= parseFloat(question4)) {
      setSubQuestion4Error(null);
    } else {
      setSubQuestion4Error(
        `Amount must be less than or equal question 5 (Currently: ${formatCurrency(parseFloat(question4 || 0))})`
      );
    }
  };

  const updateQuestion10Text = (value) => {
    setQuestion10Text(value);
    if (value && question10Number) {
      setQuestion10Error(null);
    }
  };

  const updateQuestion10Number = (value) => {
    setQuestion10Number(value);
    if (value && question10Text) {
      setQuestion10Error(null);
    }
  };

  const updateQuestion0 = (value) => {
    setQuestion0(value);
    if (value && parseFloat(value) >= parseFloat(currentDuesTotal)) {
      setQuestion0Error(null);
    } else {
      setQuestion0Error(
        `Amount is lower than current dues. Currently: ${formatCurrency(parseFloat(currentDuesTotal || 0))}`
      );
    }
  };

  const handleQuestion5Change = (value) => {
    if (value === true && question5 !== value) {
      setSubQuestion3(null);
      setSubQuestion4(null);
      setSubQuestion3Error(null);
      setSubQuestion4Error(null);
    }
    setQuestion5(value);
    if (value !== null && value !== '1') {
      setQuestion5Error(null);
    } else {
      setQuestion5Error('Please select an answer');
    }
  };
  const onSubmitForm = (completed) => {
    let formValidCopy = { ...formValid.current };
    let validForm = true;
    if (!formValidCopy.question0) {
      validForm = false;
    }
    if (!formValidCopy.question1) {
      validForm = false;
    }
    if (!formValidCopy.question2) {
      validForm = false;
    }
    if (!formValidCopy.question3) {
      validForm = false;
    }
    if (!formValidCopy.question4) {
      validForm = false;
    }
    if (!formValidCopy.question5 || question5 === null || question5 === '1') {
      validForm = false;
      setQuestion5Error('Please select an answer');
    }
    if (question5 === true) {
      if (!formValidCopy.subQuestion3) {
        validForm = false;
        setSubQuestion3Error('Amount must be entered');
      }
      if (!subQuestion4 || !formValidCopy.subQuestion4) {
        validForm = false;
        setSubQuestion4Error('Amount must be entered');
      } else if (parseFloat(subQuestion4) > parseFloat(question4)) {
        validForm = false;
      }
      setSubQuestion4Error(
        `Amount must be less than or equal question 5 (Currently: ${formatCurrency(parseFloat(question4 || 0))})`
      );
    }
    if (!formValidCopy.question6) {
      validForm = false;
    }
    if (!formValidCopy.question9) {
      validForm = false;
    }
    if (!formValidCopy.question10) {
      validForm = false;
    }
    if (
      (question10Text || question10Number) &&
      (!question10Text || !question10Number)
    ) {
      validForm = false;
      setQuestion10Error('Please enter both a description and a number');
    }

    if (!validForm) {
      console.log('Form is not valid');
      return;
    }
    let answers = [
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question1
        )[0]?.id,
        answer: question1 === null ? null : question1.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question2
        )[0]?.id,
        answer: question2 === null ? null : question2.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.subQuestion3
        )[0]?.id,
        answer: subQuestion3 === null ? null : subQuestion3.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.subQuestion4
        )[0]?.id,
        answer: subQuestion4 === null ? null : subQuestion4.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question3
        )[0]?.id,
        answer: question3 === null ? null : question3.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question4
        )[0]?.id,
        answer: question4 === null ? null : question4.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question5
        )[0]?.id,
        answer: question5 === null ? null : question5.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question6
        )[0]?.id,
        answer: question6 === null ? null : question6.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question9
        )[0]?.id,
        answer: question9 === null ? null : question9.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question10
        )[0]?.id,
        answer: question10Text ? question10Text.toString() : question10Text,
        answer_2:
          question10Number === null ? null : question10Number.toString(),
      },
      {
        question_id: questions.filter(
          (q) => q.question_id === disbursementQuestions.question0
        )[0]?.id,
        answer: question0 === null ? null : question0.toString(),
      },
    ];
    mutate({
      id: id,
      answers: answers,
      section_completed: completed,
      section: 'disbursements',
    });
  };

  return (
    <Grid container>
      {questions
        .filter((q) => q.question_id === disbursementQuestions.question0)
        .map((question) => (
          <QuestionLine
            key={1}
            questionNumber={1}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            errorOverride={question0Error}
            disabled={!editable}
            answer={question0}
            onChange={(value) => updateQuestion0(value)}
            onFormChange={(isValid) => (formValid.current.question0 = isValid)}
          />
        ))}
      {questions
        .filter((q) => q.question_id === disbursementQuestions.question1)
        .map((question) => (
          <QuestionLine
            key={1}
            questionNumber={2}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            disabled={!editable}
            answer={question1}
            onChange={(value) => setQuestion1(value)}
            onFormChange={(isValid) => (formValid.current.question1 = isValid)}
          />
        ))}

      {questions
        .filter((q) => q.question_id === disbursementQuestions.question2)
        .map((question) => (
          <QuestionLine
            key={2}
            questionNumber={3}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            disabled={!editable}
            answer={question2}
            onChange={(value) => setQuestion2(value)}
            onFormChange={(isValid) => (formValid.current.question2 = isValid)}
          />
        ))}
      {questions
        .filter((q) => q.question_id === disbursementQuestions.question3)
        .map((question) => (
          <QuestionLine
            key={5}
            questionNumber={4}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            disabled={!editable}
            answer={question3}
            onChange={(value) => setQuestion3(value)}
            onFormChange={(isValid) => (formValid.current.question3 = isValid)}
          />
        ))}
      {questions
        .filter((q) => q.question_id === disbursementQuestions.question4)
        .map((question) => (
          <QuestionLine
            key={6}
            questionNumber={5}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            disabled={!editable}
            answer={question4}
            onChange={(value) => updateQuestion5(value)}
            onFormChange={(isValid) => (formValid.current.question4 = isValid)}
          />
        ))}
      {questions
        .filter((q) => q.question_id === disbursementQuestions.question5)
        .map((question) => (
          <QuestionLine
            key={7}
            questionNumber={6}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            disabled={!editable}
            answer={question5}
            onChange={(value) => handleQuestion5Change(value)}
            errorOverride={question5Error}
            onFormChange={(isValid) => (formValid.current.question5 = isValid)}
          />
        ))}
      {question5 === true &&
        questions
          .filter((q) => q.question_id === disbursementQuestions.subQuestion3)
          .map((question) => (
            <QuestionLine
              key={3}
              questionNumber={'6a'}
              question={question.question}
              questionId={question.question_id}
              answerType={question.answer_type}
              disabled={!editable}
              answer={subQuestion3}
              startAdornment={''}
              errorOverride={subQuestion3Error}
              financial={false}
              onChange={(value) => updateSubQuestion3(value)}
              onFormChange={(isValid) =>
                (formValid.current.subQuestion3 = isValid)
              }
            />
          ))}
      {question5 === true &&
        questions
          .filter((q) => q.question_id === disbursementQuestions.subQuestion4)
          .map((question) => (
            <QuestionLine
              key={4}
              questionNumber={'6b'}
              question={question.question}
              questionId={question.question_id}
              answerType={question.answer_type}
              disabled={!editable}
              answer={subQuestion4}
              errorOverride={subQuestion4Error}
              onChange={(value) => updateSubQuestion4(value)}
              onFormChange={(isValid) =>
                (formValid.current.subQuestion4 = isValid)
              }
            />
          ))}
      {questions
        .filter((q) => q.question_id === disbursementQuestions.question6)
        .map((question) => (
          <QuestionLine
            key={8}
            questionNumber={7}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            disabled={!editable}
            answer={question6}
            onChange={(value) => setQuestion6(value)}
            onFormChange={(isValid) => (formValid.current.question6 = isValid)}
          />
        ))}
      {questions
        .filter((q) => q.question_id === disbursementQuestions.question9)
        .map((question) => (
          <QuestionLine
            key={11}
            questionNumber={8}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            disabled={!editable}
            answer={question9}
            onChange={(value) => setQuestion9(value)}
            onFormChange={(isValid) => (formValid.current.question9 = isValid)}
          />
        ))}
      {questions
        .filter((q) => q.question_id === disbursementQuestions.question10)
        .map((question) => (
          <QuestionLine
            key={12}
            questionNumber={9}
            question={question.question}
            questionId={question.question_id}
            answerType={question.answer_type}
            disabled={!editable}
            answer={question10Text}
            onChange={(value) => updateQuestion10Text(value)}
            onFormChange={(isValid) => (formValid.current.question10 = isValid)}
            answer2={question10Number}
            answer2OnChange={(value) => updateQuestion10Number(value)}
            errorOverride={question10Error}
          />
        ))}
      <Grid item xs={12} padding={2}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
          Total:{' '}
          {formatCurrency(
            parseFloat(question10Number || 0) +
              parseFloat(question1 || 0) +
              parseFloat(question2 || 0) +
              parseFloat(question3 || 0) +
              parseFloat(question4 || 0) +
              parseFloat(question6 || 0) +
              parseFloat(question9 || 0) +
              parseFloat(question0 || 0)
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
          + Cost of Goods Sold:{' '}
          {formatCurrency(
            otherQuestions.filter(
              (q) => q.question_id === 'ecea95f923634856af8800ac1c026bca'
            )[0]?.answer || 0
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
          + Cost of Bed and Breakfast:{' '}
          {formatCurrency(
            otherQuestions.filter(
              (q) => q.question_id === 'd94ef3e0e30a45c6a15134b5521a6387'
            )[0]?.answer || 0
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} padding={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} paddingBottom={2}>
        <Typography variant="body1" fontWeight={'bold'} textAlign={'right'}>
          Disbursements Total:{' '}
          {formatCurrency(
            parseFloat(question10Number || 0) +
              parseFloat(question0 || 0) +
              parseFloat(question1 || 0) +
              parseFloat(question2 || 0) +
              parseFloat(question3 || 0) +
              parseFloat(question4 || 0) +
              parseFloat(question6 || 0) +
              parseFloat(question9 || 0) +
              (otherQuestions.filter(
                (q) => q.question_id === 'd94ef3e0e30a45c6a15134b5521a6387'
              )[0]?.answer || 0) +
              (otherQuestions.filter(
                (q) => q.question_id === 'ecea95f923634856af8800ac1c026bca'
              )[0]?.answer || 0)
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} padding={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} textAlign={'right'}>
        <ButtonGroup>
          <LoadingButton
            color="green"
            loading={false}
            variant="contained"
            size="small"
            sx={{ fontWeight: 'bold' }}
            disabled={!editable}
            onClick={() => onSubmitForm(true)}
          >
            Save and Complete Disbursements
          </LoadingButton>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
