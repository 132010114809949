import React from 'react';

import { FormControl, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getReportList } from '../../endpoints/reports';

export default function DocumentTypeSelector({ type, handleTypeSelect }) {
  const { data } = useApiGet(['doc-type-selector'], getReportList, {
    enabled: true,
    retry: 1,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        {data && (
          <FormControl fullWidth>
            <InputLabel id="report-select-label">Type</InputLabel>
            <Select
              labelId="doc-type-label"
              id="doc-type-select"
              value={type}
              label="Type"
              onChange={(event) => handleTypeSelect(event.target.value)}
            >
              {data?.reports.map((docType) => (
                <MenuItem key={docType.id} value={docType.id}>
                  {docType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
}
