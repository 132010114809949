import React from 'react';

import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@mui/material';
import ValidatedTextField from '../Components/ValidatedTextField';

import { numberValidator, nameValidator } from '../utils/validators';
import TooltipWrapper from './TooltipWrapper';
import { toolTips } from '../utils/questionToolTips';

export default function QuestionLine({
  question,
  questionNumber,
  answerType,
  answer,
  onChange,
  questionId,
  onFormChange,
  answer2 = null,
  answer2OnChange = null,
  financial = true,
  errorOverride = null,
  startAdornment = '$',
  disabled = false,
  twoAnswers = false,
  errorOverride2 = null,
  disabled2 = false,
  answer3 = null,
  answer3OnChange = null,
  answer4 = null,
  answer4OnChange = false,
  onFormChange2 = null,
}) {
  const handleChange = (isValid, value) => {
    onFormChange(isValid);
    onChange(value);
  };

  const handleChange2 = (isValid, value) => {
    onFormChange(isValid);
    answer2OnChange(value);
  };

  const handleChange3 = (isValid, value) => {
    onFormChange2(isValid);
    answer3OnChange(value);
  };

  const handleChange4 = (isValid, value) => {
    onFormChange2(isValid);
    answer4OnChange(value);
  };

  const answerSwitch = () => {
    switch (answerType) {
      case 'number':
        return (
          <ValidatedTextField
            validator={numberValidator}
            startAdornment={`${questionNumber}. ${startAdornment}`}
            errorOverride={errorOverride}
            disabled={disabled}
            number={true}
            financial={financial}
            value={answer}
            onChange={(isValid, value) => handleChange(isValid, value)}
          />
        );
      case 'boolean':
        return (
          <FormControl
            size="small"
            fullWidth
            error={!!errorOverride}
            disabled={disabled}
          >
            <Select
              value={answer === null ? '1' : answer}
              onChange={(event) => onChange(event.target.value)}
            >
              <MenuItem value={'1'}>Select</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
            {!!errorOverride && (
              <FormHelperText>{errorOverride}</FormHelperText>
            )}
          </FormControl>
        );
      case 'sum':
        return <Typography variant="body1">{answer}</Typography>;

      default:
        return (
          <ValidatedTextField
            validator={nameValidator}
            startAdornment={`${questionNumber}.`}
            errorOverride={errorOverride}
            disabled={disabled}
            value={answer}
            onChange={(isValid, value) => handleChange(isValid, value)}
          />
        );
    }
  };

  return (
    <Grid container>
      <Grid item xs={9} padding>
        <Typography variant="body1">
          <b>{questionNumber}.</b> {question}{' '}
          {Object.keys(toolTips).includes(questionId) && (
            <TooltipWrapper message={toolTips[questionId]} id={questionId} />
          )}
        </Typography>
      </Grid>
      {answerType === 'text-number' ? (
        <Grid item container>
          <Grid item xs={9} padding>
            <ValidatedTextField
              startAdornment={`${questionNumber}.`}
              validator={nameValidator}
              errorOverride={errorOverride}
              disabled={disabled}
              onFocus={(event) => event.target.select()}
              value={answer}
              onChange={(isValid, value) => handleChange(isValid, value)}
            />
          </Grid>
          <Grid item xs={3} padding>
            <ValidatedTextField
              validator={numberValidator}
              errorOverride={errorOverride}
              startAdornment={`${questionNumber}. $`}
              disabled={disabled}
              financial={true}
              onFocus={(event) => event.target.select()}
              number={true}
              value={answer2}
              onChange={(isValid, value) => handleChange2(isValid, value)}
            />
          </Grid>
          {twoAnswers && (
            <Grid item container>
              <Grid item xs={9} padding>
                <ValidatedTextField
                  startAdornment={`${questionNumber + 1}.`}
                  validator={nameValidator}
                  errorOverride={errorOverride2}
                  disabled={disabled}
                  onFocus={(event) => event.target.select()}
                  value={answer3}
                  onChange={(isValid, value) => handleChange3(isValid, value)}
                />
              </Grid>
              <Grid item xs={3} padding>
                <ValidatedTextField
                  validator={numberValidator}
                  errorOverride={errorOverride2}
                  startAdornment={`${questionNumber + 1}. $`}
                  disabled={disabled}
                  onFocus={(event) => event.target.select()}
                  number={true}
                  financial={true}
                  value={answer4}
                  onChange={(isValid, value) => handleChange4(isValid, value)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid item xs={3} padding>
          {answerSwitch()}
        </Grid>
      )}
    </Grid>
  );
}
