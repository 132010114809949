import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useApiGet } from '../utils/httpClient';
import { getCountries } from '../endpoints/lookups';

export default function CountrySelector({
  handleChange,
  size = 'small',
  error = null,
  errorMessage = null,
  value,
  required = true,
}) {
  const { data: countries } = useApiGet(['donation-countries'], getCountries, {
    enabled: true,
  });

  let label = 'Country';
  if (required) {
    label = 'Country *';
  }

  return countries?.length > 0 ? (
    <Autocomplete
      size={size}
      onChange={(event, newValue) => handleChange(newValue)}
      getOptionLabel={(option) => option.name}
      variant="standard"
      value={
        countries.find((option) => option.id === value)
          ? countries.find((option) => option.id === value)
          : null
      }
      label={label}
      options={countries}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={error ? errorMessage : ''}
        />
      )}
    />
  ) : null;
}
