import React from 'react';
import { Grid, Typography } from '@mui/material';

const BnBList = () => {
  return (
    <Grid>
      <Typography variant="h1">BnB List</Typography>
    </Grid>
  );
};

export default BnBList;
