import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useApiGet } from '../../utils/httpClient';
import { getIRSList } from '../../endpoints/annualReports';
import IRSFormStateSelector from '../../Components/IRSStateSelector';
import StateProvinceSelector from '../../Components/StateProvinceSelector';
import PageHeader from '../../Components/PageHeader';
import PeoTable from '../../Components/PeoTable';

const headCells = [
  {
    id: 'chapter_name',
    firstCell: true,
    label: 'Chapter',
    width: '20%',
  },
  {
    id: 'chapter_state',
    label: 'State / Province / District',
    width: '20%',
  },
  {
    id: 'state',
    label: 'Step',
    width: '30%',
  },
  {
    id: 'assignee',
    label: 'Assigned To',
    width: '30%',
  },
];

export default function IRSTable() {
  let [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (key, value) => {
      if (!value || value === '') {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [formStatus, setFormStatus] = useState(
    searchParams.get('formStatus') ? searchParams.get('formStatus') : 'all'
  );
  const [stateProvince, setStateProvince] = useState(
    searchParams.get('stateProvince') || ''
  );
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [delaySearch, setDelaySearch] = useState(
    searchParams.get('search') || ''
  );

  // need up update search params when search changes
  useEffect(() => {
    updateSearchParams('search', search);
  }, [search, updateSearchParams]);
  // need up update params when formStatus changes
  useEffect(() => {
    updateSearchParams('formStatus', formStatus);
  }, [formStatus, updateSearchParams]);
  // need up update params when stateProvince changes
  useEffect(() => {
    updateSearchParams('stateProvince', stateProvince);
  }, [stateProvince, updateSearchParams]);

  const { data, isFetching } = useApiGet(
    [
      'roster_forms',
      rowsPerPage,
      page,
      stateProvince,
      formStatus,
      orderBy,
      order,
      search,
    ],
    getIRSList,
    {
      enabled: true,
      retry: 1,
    },
    {
      rowsPerPage,
      page,
      stateProvince,
      formStatus,
      orderBy,
      order,
      search,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(delaySearch);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [delaySearch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    window.location.href = `/irs/${id}`;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    if (!event) {
      setStateProvince('');
    } else {
      setStateProvince(event.value);
    }
  };

  const handleStateChange = (stateId) => {
    if (stateId !== 0) {
      setFormStatus(stateId);
    }
  };

  return (
    <Grid container paddingLeft={2} paddingRight={2}>
      <Grid item xs={12}>
        <PageHeader title={"Chapter IRS-LC Form's"} />
      </Grid>
      <Grid container>
        <Grid item xs={4} paddingRight={2} paddingBottom={3}>
          <TextField
            label="Search"
            id="search-field"
            size="small"
            fullWidth={true}
            onChange={(event) => setDelaySearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4} paddingRight={2}>
          <StateProvinceSelector
            irs={true}
            value={stateProvince}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={4} paddingRight={2}>
          <IRSFormStateSelector
            formState={formStatus}
            handleStateChange={handleStateChange}
            label={'Step'}
          />
        </Grid>
      </Grid>
      <PeoTable
        data={data}
        isFetching={isFetching}
        headCells={headCells}
        handleRowClick={handleClick}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
}
