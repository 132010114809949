import { request } from '../utils/httpClient';

export const getCountries = () =>
  request({
    url: '/membership/countries',
    method: 'GET',
  });

export const getStates = (data) => {
  let url = '/membership/states';
  if (data.countryId) {
    url += `?country=${data.countryId}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};

export const getPostalInfo = (data) => {
  let url = '/membership/postal_info';
  if (data.countryId) {
    url += `?country=${data.countryId}`;
  }
  if (data.postalCode) {
    url += `&postal_code=${data.postalCode}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};
