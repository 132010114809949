import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function MyChapter() {
  return (
    <Grid>
      <Typography variant="h1">My Chapter Landing Page</Typography>
    </Grid>
  );
}
