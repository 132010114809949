import React from 'react';
import { Grid, Typography } from '@mui/material';

const TLCList = () => {
  return (
    <Grid>
      <Typography variant="h1">TLC List</Typography>
    </Grid>
  );
};

export default TLCList;
